body, #root {
   min-height: 100vh;
   height: auto;
   font-family: "Helvetica Neue", Arial, sans-serif;
}

* {
   margin: 0;
   padding: 0;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

div {
   border: 0px solid red;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (min-width: 1px) {
   .vh-xs-80-8 {
      max-height: 80.8vh;
   }
   .w-xs-400 {
      width: 400px;
   }
   .pt-xs-6,
   .py-xs-6 {
     padding-top: 4rem !important;
   }
   .pe-xs-6,
   .px-xs-6 {
     padding-right: 4rem !important;
   }
   .pb-xs-6,
   .py-xs-6 {
     padding-bottom: 4rem !important;
   }
   .ps-xs-6,
   .px-xs-6 {
     padding-left: 4rem !important;
   }
   .pt-xs-7,
   .py-xs-7 {
     padding-top: 5rem !important;
   }
   .pe-xs-7,
   .px-xs-7 {
     padding-right: 5rem !important;
   }
   .pb-xs-7,
   .py-xs-7 {
     padding-bottom: 5rem !important;
   }
   .ps-xs-7,
   .px-xs-7 {
     padding-left: 5rem !important;
   }
   .pt-xs-8,
   .py-xs-8 {
     padding-top: 6rem !important;
   }
   .pe-xs-8,
   .px-xs-8 {
     padding-right: 6rem !important;
   }
   .pb-xs-8,
   .py-xs-8 {
     padding-bottom: 6rem !important;
   }
   .ps-xs-8,
   .px-xs-8 {
     padding-left: 6rem !important;
   }
   .pt-xs-9,
   .py-xs-9 {
     padding-top: 7rem !important;
   }
   .pe-xs-9,
   .px-xs-9 {
     padding-right: 7rem !important;
   }
   .pb-xs-9,
   .py-xs-9 {
     padding-bottom: 7rem !important;
   }
   .ps-xs-9,
   .px-xs-9 {
     padding-left: 7rem !important;
   }
   .pt-xs-10,
   .py-xs-10 {
     padding-top: 8rem !important;
   }
   .pe-xs-10,
   .px-xs-10 {
     padding-right: 8rem !important;
   }
   .pb-xs-10,
   .py-xs-10 {
     padding-bottom: 8rem !important;
   }
   .ps-xs-10,
   .px-xs-10 {
     padding-left: 8rem !important;
   }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
   .pt-sm-6,
   .py-sm-6 {
     padding-top: 4rem !important;
   }
   .pe-sm-6,
   .px-sm-6 {
     padding-right: 4rem !important;
   }
   .pb-sm-6,
   .py-sm-6 {
     padding-bottom: 4rem !important;
   }
   .ps-sm-6,
   .px-sm-6 {
     padding-left: 4rem !important;
   }
   .pt-sm-7,
   .py-sm-7 {
     padding-top: 5rem !important;
   }
   .pe-sm-7,
   .px-sm-7 {
     padding-right: 5rem !important;
   }
   .pb-sm-7,
   .py-sm-7 {
     padding-bottom: 5rem !important;
   }
   .ps-sm-7,
   .px-sm-7 {
     padding-left: 5rem !important;
   }
   .pt-sm-8,
   .py-sm-8 {
     padding-top: 6rem !important;
   }
   .pe-sm-8,
   .px-sm-8 {
     padding-right: 6rem !important;
   }
   .pb-sm-8,
   .py-sm-8 {
     padding-bottom: 6rem !important;
   }
   .ps-sm-8,
   .px-sm-8 {
     padding-left: 6rem !important;
   }
   .pt-sm-9,
   .py-sm-9 {
     padding-top: 7rem !important;
   }
   .pe-sm-9,
   .px-sm-9 {
     padding-right: 7rem !important;
   }
   .pb-sm-9,
   .py-sm-9 {
     padding-bottom: 7rem !important;
   }
   .ps-sm-9,
   .px-sm-9 {
     padding-left: 7rem !important;
   }
   .pt-sm-10,
   .py-sm-10 {
     padding-top: 8rem !important;
   }
   .pe-sm-10,
   .px-sm-10 {
     padding-right: 8rem !important;
   }
   .pb-sm-10,
   .py-sm-10 {
     padding-bottom: 8rem !important;
   }
   .ps-sm-10,
   .px-sm-10 {
     padding-left: 8rem !important;
   }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
   .pt-md-6,
   .py-md-6 {
     padding-top: 4rem !important;
   }
   .pe-md-6,
   .px-md-6 {
     padding-right: 4rem !important;
   }
   .pb-md-6,
   .py-md-6 {
     padding-bottom: 4rem !important;
   }
   .ps-md-6,
   .px-md-6 {
     padding-left: 4rem !important;
   }
   .pt-md-7,
   .py-md-7 {
     padding-top: 5rem !important;
   }
   .pe-md-7,
   .px-md-7 {
     padding-right: 5rem !important;
   }
   .pb-md-7,
   .py-md-7 {
     padding-bottom: 5rem !important;
   }
   .ps-md-7,
   .px-md-7 {
     padding-left: 5rem !important;
   }
   .pt-md-8,
   .py-md-8 {
     padding-top: 6rem !important;
   }
   .pe-md-8,
   .px-md-8 {
     padding-right: 6rem !important;
   }
   .pb-md-8,
   .py-md-8 {
     padding-bottom: 6rem !important;
   }
   .ps-md-8,
   .px-md-8 {
     padding-left: 6rem !important;
   }
   .pt-md-9,
   .py-md-9 {
     padding-top: 7rem !important;
   }
   .pe-md-9,
   .px-md-9 {
     padding-right: 7rem !important;
   }
   .pb-md-9,
   .py-md-9 {
     padding-bottom: 7rem !important;
   }
   .ps-md-9,
   .px-md-9 {
     padding-left: 7rem !important;
   }
   .pt-md-10,
   .py-md-10 {
     padding-top: 8rem !important;
   }
   .pe-md-10,
   .px-md-10 {
     padding-right: 8rem !important;
   }
   .pb-md-10,
   .py-md-10 {
     padding-bottom: 8rem !important;
   }
   .ps-md-10,
   .px-md-10 {
     padding-left: 8rem !important;
   }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
   .vh-lg-93 {
      max-height: 93vh;
   }
   .w-lg-600 {
      width: 600px;
   }
   .pt-lg-6,
   .py-lg-6 {
     padding-top: 4rem !important;
   }
   .pe-lg-6,
   .px-lg-6 {
     padding-right: 4rem !important;
   }
   .pb-lg-6,
   .py-lg-6 {
     padding-bottom: 4rem !important;
   }
   .ps-lg-6,
   .px-lg-6 {
     padding-left: 4rem !important;
   }
   .pt-lg-7,
   .py-lg-7 {
     padding-top: 5rem !important;
   }
   .pe-lg-7,
   .px-lg-7 {
     padding-right: 5rem !important;
   }
   .pb-lg-7,
   .py-lg-7 {
     padding-bottom: 5rem !important;
   }
   .ps-lg-7,
   .px-lg-7 {
     padding-left: 5rem !important;
   }
   .pt-lg-8,
   .py-lg-8 {
     padding-top: 6rem !important;
   }
   .pe-lg-8,
   .px-lg-8 {
     padding-right: 6rem !important;
   }
   .pb-lg-8,
   .py-lg-8 {
     padding-bottom: 6rem !important;
   }
   .ps-lg-8,
   .px-lg-8 {
     padding-left: 6rem !important;
   }
   .pt-lg-9,
   .py-lg-9 {
     padding-top: 7rem !important;
   }
   .pe-lg-9,
   .px-lg-9 {
     padding-right: 7rem !important;
   }
   .pb-lg-9,
   .py-lg-9 {
     padding-bottom: 7rem !important;
   }
   .ps-lg-9,
   .px-lg-9 {
     padding-left: 7rem !important;
   }
   .pt-lg-10,
   .py-lg-10 {
     padding-top: 8rem !important;
   }
   .pe-lg-10,
   .px-lg-10 {
     padding-right: 8rem !important;
   }
   .pb-lg-10,
   .py-lg-10 {
     padding-bottom: 8rem !important;
   }
   .ps-lg-10,
   .px-lg-10 {
     padding-left: 8rem !important;
   }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
   .pt-xl-6,
   .py-xl-6 {
     padding-top: 4rem !important;
   }
   .pe-xl-6,
   .px-xl-6 {
     padding-right: 4rem !important;
   }
   .pb-xl-6,
   .py-xl-6 {
     padding-bottom: 4rem !important;
   }
   .ps-xl-6,
   .px-xl-6 {
     padding-left: 4rem !important;
   }
   .pt-xl-7,
   .py-xl-7 {
     padding-top: 5rem !important;
   }
   .pe-xl-7,
   .px-xl-7 {
     padding-right: 5rem !important;
   }
   .pb-xl-7,
   .py-xl-7 {
     padding-bottom: 5rem !important;
   }
   .ps-xl-7,
   .px-xl-7 {
     padding-left: 5rem !important;
   }
   .pt-xl-8,
   .py-xl-8 {
     padding-top: 6rem !important;
   }
   .pe-xl-8,
   .px-xl-8 {
     padding-right: 6rem !important;
   }
   .pb-xl-8,
   .py-xl-8 {
     padding-bottom: 6rem !important;
   }
   .ps-xl-8,
   .px-xl-8 {
     padding-left: 6rem !important;
   }
   .pt-xl-9,
   .py-xl-9 {
     padding-top: 7rem !important;
   }
   .pe-xl-9,
   .px-xl-9 {
     padding-right: 7rem !important;
   }
   .pb-xl-9,
   .py-xl-9 {
     padding-bottom: 7rem !important;
   }
   .ps-xl-9,
   .px-xl-9 {
     padding-left: 7rem !important;
   }
   .pt-xl-10,
   .py-xl-10 {
     padding-top: 8rem !important;
   }
   .pe-xl-10,
   .px-xl-10 {
     padding-right: 8rem !important;
   }
   .pb-xl-10,
   .py-xl-10 {
     padding-bottom: 8rem !important;
   }
   .ps-xl-10,
   .px-xl-10 {
     padding-left: 8rem !important;
   }
}

/*
.pt-xx-6,
.py-xx-6 {
  padding-top: 4rem !important;
}
.pe-xx-6,
.px-xx-6 {
  padding-right: 4rem !important;
}
.pb-xx-6,
.py-xx-6 {
  padding-bottom: 4rem !important;
}
.ps-xx-6,
.px-xx-6 {
  padding-left: 4rem !important;
}
.pt-xx-7,
.py-xx-7 {
  padding-top: 5rem !important;
}
.pe-xx-7,
.px-xx-7 {
  padding-right: 5rem !important;
}
.pb-xx-7,
.py-xx-7 {
  padding-bottom: 5rem !important;
}
.ps-xx-7,
.px-xx-7 {
  padding-left: 5rem !important;
}
.pt-xx-8,
.py-xx-8 {
  padding-top: 6rem !important;
}
.pe-xx-8,
.px-xx-8 {
  padding-right: 6rem !important;
}
.pb-xx-8,
.py-xx-8 {
  padding-bottom: 6rem !important;
}
.ps-xx-8,
.px-xx-8 {
  padding-left: 6rem !important;
}
.pt-xx-9,
.py-xx-9 {
  padding-top: 7rem !important;
}
.pe-xx-9,
.px-xx-9 {
  padding-right: 7rem !important;
}
.pb-xx-9,
.py-xx-9 {
  padding-bottom: 7rem !important;
}
.ps-xx-9,
.px-xx-9 {
  padding-left: 7rem !important;
}
.pt-xx-10,
.py-xx-10 {
  padding-top: 8rem !important;
}
.pe-xx-10,
.px-xx-10 {
  padding-right: 8rem !important;
}
.pb-xx-10,
.py-xx-10 {
  padding-bottom: 8rem !important;
}
.ps-xx-10,
.px-xx-10 {
  padding-left: 8rem !important;
}
*/
